import EditIcon from '@mui/icons-material/Edit';
import { Box, Breadcrumbs, Button, Card, Drawer, Link, Typography } from '@mui/material';
import { GridColumns, GridRowId, GridRowParams } from '@mui/x-data-grid-pro';
import { useCallback, useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';

import DashboardHeader from 'components/dashboard-header/DashboardHeader';
import { format, parseISO } from 'date-fns';
import GridActionsCellItem from 'features/data-grid-table/GridActionsCellItem';
import DataGridLocation from 'features/myLocation/DataGridLocation';
import { AlertType } from 'features/stepper/stepperModel';
import { setSnackAlert } from 'features/stepper/stepperSlice';
import { CmUserLanguage } from 'features/user/userModel';
import { locales } from 'i18n/config';
import ConfirmComponent from './confirmationBox';
import { LocationDataInterface } from './location-model';
import { deleteLocationAsync, getLocationAsync } from './locationAPI';
import ManageLocation from './manage-location';
import { MXP_PAGE_VIEW } from 'constants/mixpanel';
import { TabsInterface } from 'features/recomendation-tab/RecomendationTab';

const MyLocation = () => {
  const [open, setOpen] = useState(false);
  const { t, i18n } = useTranslation();
  const [locations, setLocations] = useState<LocationDataInterface[]>([]);
  const [loading, setLoading] = useState(true);
  const [selectedLocation, setSelectedLocation] = useState<LocationDataInterface | null>(null);
  const dispatch = useDispatch();
  const [confirmationId, setConfirmationId] = useState<GridRowId>('');
  const [buttonLoading, setButtonLoading] = useState(false);

  const flowType = useParams().type as TabsInterface;
  useEffect(() => {
    // Send a default page view event with additional properties
    mixpanel.track_pageview({ page: MXP_PAGE_VIEW.CustomerLocations, flow: flowType });
  }, []);

  useEffect(() => {
    fetchLocation();
  }, []);

  const fetchLocation = useCallback(() => {
    getLocationAsync()
      .then((res) => {
        if (res.error) throw res.error;
        setLocations(res?.response?.result);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        dispatch(setSnackAlert({ open: true, type: AlertType.error, message: 'Failed to fetch!', timeout: 6000 }));
        setLoading(false);
      });
  }, []);

  const handleLocationUpdate = useCallback(
    (payload: LocationDataInterface, type = 'add') => {
      if (type === 'add') {
        if (!locations.some((location) => location._id === payload._id)) {
          setLocations((prev) => [payload, ...prev]);
        }
      }
      if (type === 'update') {
        setLocations((prev) => {
          const updatedPrev = prev.map((item) => {
            if (item._id === payload._id) item = payload;
            return item;
          });
          return updatedPrev;
        });
      }
    },
    [locations]
  );

  const openConfirmation = useCallback(
    (id: GridRowId) => () => {
      setConfirmationId(id);
    },
    []
  );

  const deleteLocation = useCallback(() => {
    setButtonLoading(true);
    deleteLocationAsync(confirmationId).then(() => {
      setLocations((prev) => {
        return prev.filter((location) => location.id !== confirmationId);
      });
      setButtonLoading(false);
      setConfirmationId('');
      dispatch(setSnackAlert({ open: true, type: AlertType.success, message: 'Location Deleted', timeout: 6000 }));
    });
  }, [confirmationId]);

  const editLocation = useCallback(
    (data: GridRowParams) => () => {
      setSelectedLocation(data.row);
      setOpen(true);
    },
    []
  );

  const closeDrawer = useCallback(() => {
    setOpen((prev) => !prev);
    setSelectedLocation(null);
  }, []);

  const createAddress = (data: LocationDataInterface) => {
    const { address = '', city = '', state = '', zip = '', country = '' } = data;
    let arr: string[] = [address, city, state, zip, country];
    arr = arr.filter((item) => item && item !== '');
    return arr.join(', ');
  };

  const columns: GridColumns = useMemo(
    () => [
      {
        field: 'id',
        headerName: 'ID',
        sortable: false,
        hide: true
      },
      {
        field: 'location_name',
        headerName: t('LocationTab.LocationTable.LocationLabel') || 'Location Name',
        renderHeader: () => <strong>{t('LocationTab.LocationTable.LocationLabel') || 'Location Name'}</strong>,
        editable: false,
        width: 250,
        valueGetter: (params) => params.row.location_name || ''
      },
      {
        field: 'org_name',
        headerName: t('LocationTab.LocationTable.organizationLabel') || 'Distributor Org',
        renderHeader: () => <strong>{t('LocationTab.LocationTable.organizationLabel') || 'Distributor Org'}</strong>,
        width: 180,
        editable: false,
        valueGetter: (params) => params.row.org_name || ''
      },
      {
        field: 'root_org_name',
        flex: 1,
        editable: false,
        valueGetter: (params) => params.row.parent_org_name || '',
        headerName: t('LocationTab.LocationTable.parentOrganizationLabel') || 'Distributor Parent Org',
        renderHeader: () => (
          <strong>{t('LocationTab.LocationTable.parentOrganizationLabel') || 'Distributor Parent Org'}</strong>
        )
      },
      {
        field: 'address',
        flex: 1,
        editable: false,
        valueGetter: (params) => createAddress(params.row),
        headerName: t('LocationTab.LocationTable.address') || 'Address',
        renderHeader: () => <strong>{t('LocationTab.LocationTable.address') || 'Address'}</strong>
      },
      {
        field: 'contact_info',
        headerName: t('LocationTab.LocationTable.contact') || 'Contact',
        renderHeader: () => <strong>{t('LocationTab.LocationTable.contact') || 'Contact'}</strong>,
        flex: 1,
        editable: false,
        valueGetter: (params) => params.row.contact_info,
        renderCell: (params) => <Link>{params.row.contact_info}</Link>
      },
      {
        field: 'createdAt',
        headerName: t('LocationTab.LocationTable.dateCreatedLabel') || 'Date Created',
        renderHeader: () => <strong>{t('LocationTab.LocationTable.dateCreatedLabel') || 'Date Created'}</strong>,
        sortable: true,
        editable: false,
        flex: 1,
        width: 100,
        valueGetter: (params) => params.row.createdAt,
        valueFormatter: (params) =>
          format(parseISO(params?.value), 'dd/MM/yyyy hh:mm a', { locale: locales[i18n.language as CmUserLanguage] })
      },
      {
        field: 'actions',
        type: 'actions',
        headerName: t('LocationTab.LocationTable.actionsLabel') || 'Actions',
        renderHeader: () => <strong>{t('LocationTab.LocationTable.actionsLabel') || 'Actions'}</strong>,
        sortable: false,
        disableColumnMenu: true,
        width: 150,
        getActions: (params: GridRowParams) => {
          return [
            <GridActionsCellItem
              icons={[<EditIcon />]}
              label="Edit"
              params={params}
              showInMenu={false}
              visible={true}
              handleEdit={editLocation(params)}
            />,
            <GridActionsCellItem
              label={t('MySolutionsTab.MySolutionTable.Actions.deleteLabel') || 'Delete'}
              onClick={openConfirmation(params.id)}
              showInMenu={true}
              visible={true}
              params={params}
            />
          ];
        }
      }
    ],
    []
  );
  const options = {
    rows: locations,
    rowsPerPageOptions: [10, 20, 30],
    columns: columns,
    hideFooterSelectedRowCount: true,
    showCellRightBorder: false,
    showColumnRightBorder: false,
    pagination: true,
    checkboxSelection: true
  };

  const confirmationOptions = {
    open: confirmationId ? true : false,
    title: t('LocationTab.LocationTable.Actions.deleteActionLabel'),
    messages: [t('LocationTab.LocationTable.Actions.deleteConfirmMsg')],
    onClose: () => setConfirmationId(''),
    onAction: deleteLocation,
    buttonTexts: [
      t('LocationTab.LocationTable.Actions.cancelLabel'),
      t('LocationTab.LocationTable.Actions.deleteLabel')
    ]
  };

  return (
    <Box sx={{ margin: '10px' }}>
      <DashboardHeader />
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', mb: 2, mx: 2 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
          <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 1 }}>
            <Link underline="hover" color="inherit" href="/">
              {t('Flow.CommonLabels.dashBoardBreadcrumbLabel')}
            </Link>
            <Typography color="text.primary">{t('LocationTab.LocationTable.customerLocations')}</Typography>
          </Breadcrumbs>
          <Typography component={'span'} variant="h6">
            {t('LocationTab.LocationTable.customerLocations')}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
          <Button
            sx={{ textTransform: 'none', fontWeight: 'bold' }}
            variant="contained"
            onClick={() => setOpen((prev) => !prev)}
          >
            {t('LocationTab.LocationTable.createLocations')}
          </Button>
        </Box>
      </Box>
      <Card sx={{ mx: 2 }}>
        <Box component={'div'} sx={{ marginTop: '20px', height: 'calc(100vh - 185px)' }}>
          <DataGridLocation {...options} loading={loading} />
        </Box>
      </Card>
      <Drawer anchor="right" open={open} onClose={closeDrawer}>
        <ManageLocation
          closeDrawer={closeDrawer}
          addLocation={handleLocationUpdate}
          selectedLocation={selectedLocation}
        />
      </Drawer>
      <ConfirmComponent loading={buttonLoading} {...confirmationOptions} />
    </Box>
  );
};

export default MyLocation;
