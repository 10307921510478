import ClearIcon from '@mui/icons-material/Clear';
import { Grid, IconButton, TextField } from '@mui/material';
import { GridToolbar } from '@mui/x-data-grid-pro';

interface LocationToolboxInterface {
    value: string,
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    clearSearch: () => void;
    isFeedbackDataGrid?: boolean
}
const LocationToolbar = ({value, onChange, clearSearch, isFeedbackDataGrid}: LocationToolboxInterface) => {
  return (
    <Grid container sx={{width: '100%', px: 1}} spacing={2}> 
        <Grid item xs={6}>
        {!isFeedbackDataGrid && <GridToolbar /> }
        </Grid>
        <Grid item xs={6} sx={{display: 'flex', justifyContent: 'flex-end'}}>
        <TextField
        variant="outlined"
        size='small'
        value={value}
        onChange={onChange}
        placeholder="Search…"
        InputProps={{
          endAdornment: (
            <IconButton
              title="Clear"
              aria-label="Clear"
              size="small"
              onClick={() => clearSearch()}
              sx={{ visibility: value ? "visible" : "hidden" }}
            >
              <ClearIcon fontSize="small" />
            </IconButton>
          )
        }}
      />
        </Grid>
    </Grid>
  )
}

export default LocationToolbar