import { AdditionalCostFeesEnum, Charge } from 'features/additionalCostFees/additonalCostFeesModel';
import { CostInfoState } from 'features/costInfo/costInfoSlice';
import { CribSelectionUpdateResponse } from 'features/machineSettings/machine-model';
import { Currency, UserOrgType } from 'features/user/userModel';
import { CostInfoKeys, ItemInfoKeys, customerSalesKeys } from './guidedApiKeys';

// utilise models of automated flow if similar otherwise create a new model
export { InventoryType } from 'features/criteria-ranking/criteriaRankingModel';

export enum Criteria {
  stock_out = 'stock_out',
  restocking_time = 'restocking_time',
  overuse =  'overuse',
  pay_per_use = 'pay_per_use',
  trip_time = 'trip_time',
  fod_fme = 'fod_fme',
  floor_space = 'floor_space',
  calibration_repair = 'calibration_repair',
  stock_rotation = 'stock_rotation',
  budget = 'budget',
  inventory_shrinkage = 'inventory_shrinkage',
}

// export enum APICriteria {
//   stock_out = 'stock_out',
//   restocking_time = 'restocking_time',
//   overuse =  'overuse',
//   pay_per_use = 'pay_per_use',
//   trip_time = 'trip_time',
//   fod_fme = 'fod_fme',
//   floor_space = 'floor_space',
//   calibration_repair = 'calibration_repair',
//   stock_rotation = 'stock_rotation',
//   budget = 'budget',
//   inventory_shrinkage = 'inventory_shrinkage',
// }
// ENUMS GUIDED
export enum SecurityGuided {
  high = 'High',
  medium = 'Medium',
  low = 'Low'
}
export enum timeWindowGuided {
  weekly = 'Weekly',
  monthly = 'Monthly',
  quarterly = 'Quarterly',
  semiAnnual = 'Semi Annual',
  yearly = 'Yearly'
}
export enum CostInformationGuided {
  stateSales = 'State sales tax',
  freightEstimate = 'Freight Estimate',
  stockingLabor = 'Stocking Labor Cost /week (Hrs)',
  laborRatePerHour = 'Labor Rate Per Hour',
  leaseAgreement = 'Lease/VMI Agreement Term (Yrs)',
  estimatedRepair = 'Estimated Repair & MTC Costs Over Term',
  tripTime = 'Total Trip Time (Min)',
  budget = 'budget'
}

export enum MachineSelection {
  express_toolbox = 'express_toolbox',
  toolbox = 'toolbox',
  express_locker = 'express_locker',
  prolock = 'prolock',
  prostock = 'prostock',
  flexsense = 'flexsense',
  ecab = 'ecab',
  multistore = 'multistore',
  accucab = 'accucab',
  accudrawer = 'accudrawer',
  cablock = 'cablock',
  edrawer = 'edrawer',
  fliptop = 'fliptop',
  accuport = 'accuport',
  weighstation_double = 'weighstation_double',
  weighstation_single = 'weighstation_single',
  hybrid_accucab_double = 'hybrid_accucab_double',
  hybrid_accucab_single = 'hybrid_accucab_single',
}

export enum MachineCategories {
  express_machines = 'express_machines',
  rfid_machines = 'rfid_machines',
  accuport_machines = 'accuport_machines'
}

export enum RestockingFrequencyInterface {
  daily = 'Daily',
  TwiceWeekly = '2x weekly',
  weekly = 'Weekly',
  bi_weekly = 'Bi Weekly',
  monthly = 'Monthly'
}
export enum ItemDurable {
  large_durable_hand_tools = 'Large Durable Hand Tools'
}

export enum FlowType {
  Guided= 'Guided',
  Automated='Automated',
  ROI='ROICalculator'
}

export interface MachineSelectionROI {
  machine_type: MachineSelection;
  main_qty?: number;
  aux_qty?: number;
  comment?: string;
}

// INTERFACES GUIDED
export interface ItemInfoState {
  [ItemInfoKeys.items_to_start_the_shift]: number | undefined;
  [ItemInfoKeys.capacity_for_growth]: number | undefined;
  [ItemInfoKeys.restocking_frequency]: string | undefined;
  [ItemInfoKeys.safety_stock_percentage]: number | undefined;
  [ItemInfoKeys.area_size]: number | undefined;
}
export interface ItemInfoStateNumbers {
  items_to_start_the_shift: number | undefined;
  capacity_for_growth: number | undefined;
}
export interface CustomerDataInterface {
  consumption_reduction: number | undefined;
  employees_per_shift: number | undefined;
  avg_labor_rate_per_hour: number | undefined;
  total_trip_time: number| undefined;
  po_per_month: number | undefined;
  cost_per_po: number | undefined;
}
export interface CustomerSalesOpportunityInterface {
  [customerSalesKeys.estimated_revenue_twelve_months]: number | string | undefined;
  [customerSalesKeys.average_gross_profit]: number | string | undefined;
  [customerSalesKeys.additional_halo_revenue]: number | string | undefined;
  [customerSalesKeys.gross_profit_margin_percentage_halo_revenue]: number | string | undefined;
}

export interface CostInformationInterface {
  [customerSalesKeys.estimated_revenue_twelve_months]: number | string | undefined;
  [customerSalesKeys.average_gross_profit]: number | string | undefined;
  [customerSalesKeys.additional_halo_revenue]: number | string | undefined;
  [customerSalesKeys.gross_profit_margin_percentage_halo_revenue]: number | string | undefined;
  [CostInfoKeys.stocking_labor]:  number | string | undefined;
  [CostInfoKeys.lease_agreement]?:   number | string | undefined;
}
export interface customerSalesInfoElement {
  name: string;
  value: string | number | undefined;
}

export interface CustomerDataElement {
  name: string;
  value: number;
}
export interface itemsInfoElement {
  name: string;
  value: number | string | undefined;
}
export interface ResponseAreaObject {
  _id: string;
  area_name: string;
  root_org_id: string;
  root_org_name: string;
  org_id: string;
  org_name: string;
  location_id: string;
  location_name: string;
  created_by: string;
  createdAt: string;
  updatedAt: string;
  parent_id:string;
  id: string;
  __v: 0;
  start_date?: Date | null;
  parent_org_id: string;
  parent_org_name: string;
}

export interface ResponseProductCategoryObject {
  _id: string;
  item_key: string;
  item_name: string;
  item_type: string;
  id: string;
  description: string;
}

export interface CreateAreaPayloadInterface {
  area_name: string;
  root_org_id: string;
  root_org_name: string;
  org_id: string;
  org_name: string;
  location_id: string;
  location_name: string;
}
export interface ItemQuantityGuided {
  type?: string | '';
  quantity?: number;
  security?: SecurityGuided | '';
  time_window?: timeWindowGuided | '';
  category_type?: string | null;
  comment?: string | '';
}

export interface excelTable {
  name: string;
  filterButton?: boolean;
  totalsRowLabel?: string;
  totalsRowFunction?: 'none' | 'average' | 'countNums' | 'count' | 'max' | 'min' | 'stdDev' | 'var' | 'sum' | 'custom';
  totalsRowFormula?: string;
}

export interface UpdateSolutionPayloadInterface {
  flowId?: string;
  area?: string;
  criteria_ranking?: Criteria[];
  flow_inventory_data?: ItemQuantityGuided[];
  flow_inventory_info?: ItemInfoState;
  cost_info?: CostInfoState;
  customer_sales_opportunity?: CustomerSalesOpportunityInterface;
  machine_selection?: string[] | [];
  machine_selection_roi: MachineSelectionROI[];
  completed?: boolean;
  shared?: boolean;
  rfid_machines?: boolean;
  express_machines?: boolean;
  accuport_machines?: boolean;
  customer_data?: CustomerDataInterface;
  customer_view_results?: CustomerViewResults;
  cm_hosting_data?: boolean;
  [AdditionalCostFeesEnum.additionalCosts]: Charge[];
  [AdditionalCostFeesEnum.additionalFees]: Charge[],
  connections?: ActiveConnections,
  include_consignment: boolean
}

export interface ResponseSolutionObject {
  _id: string;
  name: string;
  user_id: string;
  completed: boolean;
  shared: boolean;
  area: ResponseAreaObject;
  flow_type: FlowType;
  crib_config?: CribConfig;
  cost_info: CostInfoState;
  criteria_ranking: Criteria[];
  machine_selection: MachineSelection[];
  machine_selection_roi: MachineSelectionROI[];
  flow_inventory_info: ItemInfoState;
  flow_inventory_data: ItemQuantityGuided[];
  customer_sales_opportunity: CustomerSalesOpportunityInterface;
  created_by: string;
  createdAt: string;
  updatedAt: string;
  id: string;
  rfid_machines: boolean;
  express_machines: boolean;
  accuport_machines: boolean;
  customer_data: CustomerDataInterface;
  customer_view_results?: CustomerViewResults;
  cm_hosting_data?: boolean;
  settings?: CribSelectionUpdateResponse | null;
  [AdditionalCostFeesEnum.additionalCosts]: Charge[] | [];
  [AdditionalCostFeesEnum.additionalFees]: Charge[] | [];  
  __v: 0;
  currency: Currency;
  errors?: string[];
  org_type?: UserOrgType;
  price_group?: string;
  connections: ActiveConnections;
  include_consignment: boolean;
}

export interface ResponseRecommendedSolutions {
  crib_config?: CribConfig;
  currency: Currency;
}

export interface AreaFetchingPayloadInterface {
  root_org_id: string;
  location_id: string;
  org_id: string;
}

export interface SolutionCost {
    TOTALSolutionCost: number;
    HardwareCost: number;
    SolutionCostPerYear: number;
    MonthlyCost: number;
    ROS: number;
    ROA: number;
    ROI: number;
    Payback: number;
    HardwareTaxCost: number;
    FreightEstimate: number;
    TotalHardware_Software_InstallationCosts_IncludingTax_Freight: number;
    TotalStockingLaborCostsOverTerm: number;
    LicenseAndSupportCostOverTerm: number;
    EstimatedRepairCostsOverTerm: number;
    Total_LicensingSubscription_SupportCostsOverTerm: number;
    expectedCost: number;
    CribMasterHostingCostOverTerm: number;
    AdditionalInitialImplementationCost: number;
    AdditionalRecurringCostOverTerm: number;
    InitialEndCustomerImplementationFees: number;
    RecurringRecurringEndCustomerFeesOverTerm: number;
    AllAdditionalCostsAndFees: CostFeesItem[];
    [key: string]: number | CostFeesItem[];
}

export interface CostFeesItem {
  costsFeesKey: string;
  costsFeesValue: number;
  chargeCategory: ChargesCategory;
  chargesKey: string;
}

export enum ChargesCategory {
  Fees = 'fees',
  Costs = 'costs'
}

export interface DynamicRowStyle {
  font: {
    italic: boolean;
  };
}

export interface Solution {
  Main: string[];
  Aux: string[];
  Criteria_Weighted_Scores: {
    TotalCriteriaScore: number;
  };
  Solution_Cost: SolutionCost;
}

export interface CribDetailsCost {
  crib_certified_price: number;
  crib_sub_type: 'main' | 'aux';
}

export interface CribDetails {
  [key: string]: {
    imageUrl: string;
    description: string;
    cost: CribDetailsCost[];
  };
}

export interface CribConfig {
  Solutions: Solution[];
  CribDetails: CribDetails;
  Error?: string;
  flowId: string;
}
export interface SubmitFeedbackInterface {
  feedback: string;
  root_org_id: string;
  root_org_name: string;
  org_id: string;
  org_name: string;
  parent_org_id: string
}
export interface CustomerViewResultsFields {
  per_month: number;
  per_year: number;
}

export type CustomerViewResultsKeys =
  | 'consumption_reduction'
  | 'working_capital'
  | 'employee_productivity'
  | 'order_management'
  | 'total_savings';

export type CustomerViewResults = Record<CustomerViewResultsKeys, CustomerViewResultsFields>;


export interface ResponseCriteriaList {
  id: string;
  _id: string;
  criteria_key: Criteria;
  criteria_name: Criteria;
  criteria_name_lang: { [key: string]: string; };
  description: string;
  description_lang: { [key: string]: string; };
  criteria_type: string[];
  criteria_type_lang: { [key: string]: string; };
}

export enum ActiveConnections {
  upto_100 = 'upto_100',
  upto_500 = 'upto_500',
  above_500 = 'above_500'
}
