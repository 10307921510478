import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from 'app/store';
import { excelFileData, getPreasignedURL, updateFlowData } from './uploadAPI';
import { ReducerKeys } from 'app/reducerKeys';
import { FileWithPath } from 'file-selector';
import { ResponseCribResult, inputParams } from './uploadModel';
import { convertKeyToSnake } from 'utils/helpers';
import { revertAll } from 'app/actions';

export interface UploadState {
  files: FileWithPath[];
  status: 'idle' | 'loading' | 'failed' | 'success';
  area: string;
  loading: boolean;
  flowId: string;
  flowDataResult: Array<ResponseCribResult> | undefined;
}

const initialState: UploadState = {
  files: [],
  status: 'idle',
  area: '',
  loading: true,
  flowId: '',
  flowDataResult: undefined
};

export const uploadFileAsync = createAsyncThunk(
  `${ReducerKeys.upload}/excelFileData`,
  async (data: inputParams) => {
    const { file } = data;
    const table = data.table;
    const convertedTable = table?.map((item) => {
      return convertKeyToSnake(item);
    });
    const res = await getPreasignedURL(data);
    if (res.response) {
      await excelFileData(file, res.response);
      const payload = {
        flowId: res?.response?.flow_id,
        file_url: res?.response?.fileUploadURL,
        item_data: convertedTable
      };
      await updateFlowData(payload);
    }
    return res?.response?.flow_id || '';
  }
);

export const uploadSlice = createSlice({
  name: ReducerKeys.upload,
  initialState,
  reducers: {
    uploadFiles: (state, action: PayloadAction<FileWithPath[]>) => {
      state.files = action.payload;
    },
    deleteFiles: (state, action: PayloadAction<FileWithPath>) => {
      let existingFiles = [...state.files];
      existingFiles = existingFiles.filter(function (obj) {
        return obj.path !== action.payload.path;
      });
      state.files = existingFiles;
    },
    setArea: (state, action: PayloadAction<string>) => {
      state.area = action.payload;
    },
    setFlowDataResult: (state, action) => {
      state.flowDataResult = action.payload;
    }
  },

  extraReducers: (builder) => {
    builder
      .addCase(uploadFileAsync.pending, (state) => {
        state.status = 'loading';
        state.loading = true;
      })
      .addCase(uploadFileAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.loading = false;
        state.files = initialState.files;
        state.flowId = action.payload;
      })
      .addCase(uploadFileAsync.rejected, (state) => {
        state.status = 'failed';
        state.files = initialState.files;
      })
      .addCase(revertAll, () => initialState);
  }
});

export const { uploadFiles, deleteFiles, setArea, setFlowDataResult } = uploadSlice.actions;

export const selectUploadState = (state: RootState) => state.upload;
export const selectUploadArea = (state: RootState) => state.upload.area;
export const selectFlowId = (state: RootState) => state.upload.flowId;
export const selectFlowData = (state: RootState) => state.upload.flowDataResult;
export const selectUploadStatus = (state: RootState) => state.upload.status;

export const { reducer: uploadReducer, name: uploadKey } = uploadSlice;
