import MoreVertIcon from '@mui/icons-material/MoreVert';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import RightToolbar from 'components/header/rightToolbar';
import mixpanel from 'mixpanel-browser';
import { ChangeEvent, lazy, MouseEvent, Suspense, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import { Backdrop, CircularProgress } from '@mui/material';

import { useAppDispatch, useAppSelector } from 'app/hooks';
import Loader from 'components/loader/Loader';
import { MXP_PAGE_VIEW } from 'constants/mixpanel';
import CustomerView from 'features/guided/customer-view/CustomerView';
import DirectView from 'features/guided/customer-view/directView';
import {
  selectAllowShare,
  selectGuidedState,
  selectSolutionNameGuided,
  selectSolutionSpecificCurrency,
  selectUserOrgType,
  setAllowShare,
  setIncludeConsignment
} from 'features/guided/guidedSlice';
import OpportunityView from 'features/guided/opportunity-view/OpportunityView';
import { UserOrgType } from 'features/user/userModel';
import { selectUserDetails } from 'features/user/userSlice';
import { TabsInterface } from 'features/recomendation-tab/RecomendationTab';

export enum ViewType {
  Opportunity = 'Opportunity View',
  customer = 'Customer View'
}

const LazyExportExcelWrapper = lazy(() => import('./LazyExportExcel'));
const LazyExportPDFComponent = lazy(() => import('./ExportPDFComponent'));

const ResultsGuided = ({ pdfView }: { pdfView?: boolean }) => {
  const [downloadAnchorEl, setDownloadAnchorEl] = useState<null | HTMLElement>(null);
  const { t } = useTranslation();
  const ref = useRef();
  const userDetails = useAppSelector(selectUserDetails);
  const userOrgType = useAppSelector(selectUserOrgType);
  const [viewType, setViewType] = useState<ViewType>(
    userOrgType === UserOrgType.direct ? ViewType.customer : ViewType.Opportunity
  );
  const [openExcel, setOpenExcel] = useState(false);
  const [openPDF, setOpenPDF] = useState(false);
  const [pdfLoading, setPdfLoading] = useState(false);

  const guidedState = useAppSelector(selectGuidedState);
  const solSpecificCurrency = useAppSelector(selectSolutionSpecificCurrency);

  const solutionName = useAppSelector(selectSolutionNameGuided);
  const { type } = useParams();
  const flowType = type as TabsInterface;
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { search } = useLocation();
  const allowShare = useAppSelector(selectAllowShare);
  const params = new URLSearchParams(search);
  const isShared = params.get('shared');

  const includeConsignment = guidedState.include_consignment;

  /*
  working capital - estimated_revenue_twelve_months/12 per year
  1. Direct View - 
    - cost info(sliders)
    - SavingsTable
    - ROS , ROI and payback - working capital affects these values

  2. Distributor View 

    a. Customer View
      - cost info(sliders)
      - SavingsTable
   
    b. Opportunity View 
      - cost info(sliders)
      - ROS, ROI etc
*/

  useEffect(() => {
    // Send a default page view event with additional properties
    mixpanel.track_pageview({ page: MXP_PAGE_VIEW.Result, flow: flowType });
  }, []);

  const handleViewChange = () => {
    setViewType(viewType === ViewType.Opportunity ? ViewType.customer : ViewType.Opportunity);
  };

  const handleDownloadButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
    setDownloadAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setDownloadAnchorEl(null);
  };

  const printPDFData = () => {
    setPdfLoading(true);
    setOpenPDF(true);
  };

  const onExportPDFComplete = () => {
    setOpenPDF(false);
    setPdfLoading(false);
  };

  const handleAllowShare = (event: ChangeEvent<HTMLInputElement>) => dispatch(setAllowShare(event.target.checked));
  const handleConsignmentChange = (event: ChangeEvent<HTMLInputElement>) =>
    dispatch(setIncludeConsignment(event.target.checked));

  const printExcelData = () => {
    setOpenExcel(true);
  };

  const onExportComplete = () => {
    setOpenExcel(false);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      {!pdfView && (
        <>
          <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} open={pdfLoading}>
              <CircularProgress color="inherit" />
            </Backdrop>
            <Stack spacing={0} sx={{ mr: 2 }}>
              <Typography variant="h5" sx={{ fontSize: 18 }} component="div">
                {t('Flow.GuidedTab.ResultsStep.title') ||
                  'Recommendation & Savings (Assuming Currently Inventory Method is Manual)'}
              </Typography>
              <Typography variant="subtitle1" component="div">
                {t('Flow.GuidedTab.ResultsStep.subTitle') ||
                  'Based on the information provided, we have calculated the best machines to accommodate your needs. *NOTE: This is estimate only Based on Profit calculators'}
              </Typography>
            </Stack>

            <Box sx={{ display: 'flex', minWidth: userOrgType !== UserOrgType.direct ? 375 : 200 }}>
              {userOrgType !== UserOrgType.direct && (
                <FormControlLabel
                  control={
                    <Switch
                      size="small"
                      checked={viewType === ViewType.Opportunity}
                      onChange={handleViewChange}
                      aria-label="Opportunity view switch"
                    />
                  }
                  label={viewType}
                />
              )}
              {(userOrgType === UserOrgType.direct || viewType === ViewType.customer) && (
                <FormControlLabel
                  sx={{ width: 'inherit' }}
                  label={t('Flow.GuidedTab.ResultsStep.includeConsignmentLabel') || 'Include Consignment Saving'}
                  control={
                    <Checkbox
                      size="small"
                      name="Share"
                      checked={!!includeConsignment}
                      onChange={handleConsignmentChange}
                    />
                  }
                />
              )}
              <FormControlLabel
                disabled={isShared === 'true' ? true : false}
                label="Make Shareable"
                control={<Checkbox size="small" name="Share" checked={allowShare} onChange={handleAllowShare} />}
              />
              <IconButton
                sx={{ m: 0.5 }}
                aria-label="download"
                color="default"
                size="medium"
                onClick={handleDownloadButtonClick}
              >
                <MoreVertIcon fontSize="small" sx={{ color: theme.palette.grey[600], cursor: 'pointer' }} />
              </IconButton>
              <Menu
                id="basic-menu"
                anchorEl={downloadAnchorEl}
                open={Boolean(downloadAnchorEl)}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button'
                }}
              >
                <MenuItem onClick={() => window.print()}>
                  {t('Flow.GuidedTab.ResultsStep.Actions.printLabel') || 'Print'}
                </MenuItem>
                <MenuItem onClick={printExcelData}>
                  {t('Flow.GuidedTab.ResultsStep.Actions.exportExcelLabel') || 'Export to Excel'}
                </MenuItem>
                <MenuItem onClick={printPDFData}>
                  {t('Flow.GuidedTab.ResultsStep.Actions.exportPDFLabel') || 'Export to PDF'}
                </MenuItem>
              </Menu>
            </Box>
          </Box>
        </>
      )}
      {userOrgType === UserOrgType.direct ? (
        <DirectView />
      ) : viewType === ViewType.Opportunity ? (
        <OpportunityView pdfView={pdfView} />
      ) : (
        <CustomerView pdfView={pdfView} />
      )}

      {openExcel && (
        <Suspense fallback={<Loader />}>
          <LazyExportExcelWrapper
            guidedState={guidedState}
            userDetails={userDetails}
            solSpecificCurrency={solSpecificCurrency}
            viewType={viewType}
            onExportComplete={onExportComplete}
            userOrgType={userOrgType}
            flowType={flowType}
          />
        </Suspense>
      )}

      {openPDF && (
        <Suspense fallback={<Loader />}>
          <LazyExportPDFComponent
            solutionName={solutionName}
            forwardedRef={ref}
            onExportPDFComplete={onExportPDFComplete}
          />
        </Suspense>
      )}

      <Box sx={{ height: '0px', width: '0px', overflow: 'hidden' }}>
        <Box
          sx={{
            p: 4,
            background: theme.palette.background.default,
            width: '1470px',
            margin: 0,
            overflow: 'hidden'
          }}
          ref={ref}
        >
          <Box sx={{ display: 'flex', width: '100%', justifyContent: 'right', mb: 4 }}>
            <RightToolbar />
          </Box>
          <Stack spacing={0} sx={{ mr: 2, mb: 3 }}>
            <Typography variant="h5" sx={{ fontSize: 18 }} component="div">
              {t('Flow.GuidedTab.ResultsStep.title') ||
                'Recommendation & Savings (Assuming Currently Inventory Method is Manual)'}
            </Typography>
            <Typography variant="subtitle1" component="div">
              {t('Flow.GuidedTab.ResultsStep.subTitle') ||
                'Based on the information provided, we have calculated the best machines to accommodate your needs. *NOTE: This is estimate only Based on Profit calculators'}
            </Typography>
          </Stack>
          {userOrgType === UserOrgType.direct ? (
            <DirectView pdfView={true} />
          ) : viewType === ViewType.Opportunity ? (
            <OpportunityView pdfView={true} />
          ) : (
            <CustomerView pdfView={true} />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default ResultsGuided;
