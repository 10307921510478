import axios, { AxiosError } from 'axios';
import { FileWithPath } from 'file-selector';
import {
  ResponseDataUpload,
  GetPreasignedURL,
  inputParams,
  RequestSaveFileData,
  ResponseGetFlowDataByID
} from './uploadModel';
import { AxiosAPIError, handleError } from 'utils/apiError';
import axiosConfig from 'app/axiosConfig';

export const getPreasignedURL = async (
  inputData: inputParams
): Promise<{
  response: GetPreasignedURL | null;
  error: AxiosAPIError | null;
}> => {
  const { file, area } = inputData;
  const userId = 1; //TO-DO : Need to get this from Login API

  const data = await axiosConfig.get<GetPreasignedURL>(
    `/api/uploads/link?area_name=${area}&file_name=${file.path}&user_id=${userId}`
  );
  return { response: data?.data, error: null };
};

export const excelFileData = async (
  file: FileWithPath,
  getPreasignedURLData: GetPreasignedURL
): Promise<{
  data: ResponseDataUpload | null;
  error: AxiosAPIError | null;
}> => {
  const headers = {
    'x-ms-blob-type': 'BlockBlob'
  };

  const { fileUploadURL } = getPreasignedURLData;
  const config = file;

  const data = await axios.put<ResponseDataUpload>(fileUploadURL, config, {
    headers: headers
  });
  return { data: data.data, error: null };
};

export const updateFlowData = async (
  payload: RequestSaveFileData
): Promise<{
  data: ResponseGetFlowDataByID | null;
  error: AxiosAPIError | null;
}> => {
  try {
    const res = await axiosConfig.put(`/api/flow/${payload.flowId}`, payload);
    return { data: res.data, error: null };
  } catch (err) {
    const errors = err as Error | AxiosError;
    throw { data: null, error: handleError(errors) };
  }
};
