import { RowExcel } from 'components/parser/parserModel';
import { AdditionalCostFeesEnum, Charge } from 'features/additionalCostFees/additonalCostFeesModel';
import { combineCribTypeObjects } from 'features/additionalCostFees/costFeesAPI';
import { emptyCharge } from 'features/additionalCostFees/costFeesSlice';
import { emptyItemRow } from 'features/guided/guided-constants';
import { FlowType, MachineSelection, ResponseSolutionObject, UpdateSolutionPayloadInterface } from 'features/guided/guidedModel';
import {
  GuidedState,
  costInfoDefault,
  customerDataDefault,
  customerSalesDefault,
  customerViewResultDefault,
  flowInventoryInfoDefault,
  cribConfigDefault
} from 'features/guided/guidedSlice';
import { TabsInterface } from 'features/recomendation-tab/RecomendationTab';
import { StepperPages } from 'features/stepper/stepperModel';
import { ResponseItemData } from 'features/upload/uploadModel';
import { Currency } from 'features/user/userModel';
import { get } from 'lodash';

export const convertKeyToSnake = (obj: RowExcel) => {
  const newObj: { [key: string]: string | number } = {};
  for (const [key, value] of Object.entries(obj)) {
    let newkey = key.split(' ').join('_');
    newkey = newkey.toLowerCase();
    newObj[newkey] = value;
  }
  return newObj;
};

export const convertSnakeToKey = (obj: ResponseItemData) => {
  const newObj: { [key: string]: string | number } = {};
  for (const [name, value] of Object.entries(obj)) {
    let key = name.split('_');
    key = key.map((item) => {
      return item.charAt(0).toUpperCase() + item.slice(1);
    });
    const newkey = key.join(' ');
    newObj[newkey] = value;
  }
  return newObj;
};

const getChargeValue = (resp: ResponseSolutionObject, key: AdditionalCostFeesEnum) => {
  const charges = combineCribTypeObjects(resp[key]);
  if(charges?.length){
    return charges;
  }else if(resp.settings && resp.settings[key]?.length){
    return combineCribTypeObjects(resp.settings[key])
  }else{
    return [emptyCharge]
  }
}

export const getGuidedStateObject = (resp: ResponseSolutionObject): GuidedState => {
  const state = {
    name: resp.name,
    area: resp.area?.id,
    crib_config: resp.crib_config || cribConfigDefault,
    criteria_ranking: resp.criteria_ranking,
    selectedArea: resp.area,
    cost_info: resp.cost_info || costInfoDefault,
    flow_inventory_info: resp.flow_inventory_info || flowInventoryInfoDefault,
    flow_inventory_data: resp.flow_inventory_data.length ? resp.flow_inventory_data : [emptyItemRow],
    customer_sales_opportunity: resp.customer_sales_opportunity || customerSalesDefault,
    machine_selection: resp.machine_selection,
    machine_selection_roi: resp.machine_selection_roi,
    completed: resp.completed,
    shared: resp.shared,
    rfid_machines: resp.rfid_machines,
    express_machines: resp.express_machines,
    accuport_machines: resp.accuport_machines,
    customer_data: resp.customer_data || customerDataDefault,
    customer_view_results: resp.customer_view_results || customerViewResultDefault,
    solutionBuiltCurrency: resp.currency || Currency.Doller,
    criteria_entire_list: [],
    fetchAllCriteriaLoading: false,
    settings: resp.settings,
    cm_hosting_data: resp.cm_hosting_data,
    currency: resp.currency || Currency.Doller,
    [AdditionalCostFeesEnum.additionalCosts]: getChargeValue(resp, AdditionalCostFeesEnum.additionalCosts),
    [AdditionalCostFeesEnum.additionalFees]: getChargeValue(resp, AdditionalCostFeesEnum.additionalFees),
    flow_type: resp.flow_type,
    user_id: resp?.user_id,
    org_type: resp.org_type,
    price_group: resp.price_group,
    connections: resp.connections,
    include_consignment: resp.include_consignment
  };
  return state;
};

export const generateGuidedFlowPayload = (guided: GuidedState): UpdateSolutionPayloadInterface => {
  return  {
    criteria_ranking: guided.criteria_ranking,
    area: get(guided, ['area']) || '',
    flow_inventory_info: guided.flow_inventory_info,
    flow_inventory_data: guided.flow_inventory_data,
    cost_info: guided.cost_info, 
    customer_sales_opportunity: guided.customer_sales_opportunity,
    machine_selection: guided.machine_selection,
    machine_selection_roi: guided.machine_selection_roi,
    completed: guided.completed,
    shared: guided.shared,
    rfid_machines: guided.rfid_machines,
    express_machines: guided.express_machines,
    accuport_machines: guided.accuport_machines,
    customer_data: guided.customer_data,
    customer_view_results: guided.customer_view_results,
    cm_hosting_data: guided.cm_hosting_data,
    [AdditionalCostFeesEnum.additionalCosts]: guided[AdditionalCostFeesEnum.additionalCosts],
    [AdditionalCostFeesEnum.additionalFees]: guided[AdditionalCostFeesEnum.additionalFees],
    connections: guided.connections,
    include_consignment: guided.include_consignment
  };
}

export const stepNameToNumberGuided = (name: string) => {
  if(name === StepperPages.CriteriaGuided) return 0;
  if(name === StepperPages.DetailsGuided) return 1;
  if(name === StepperPages.costInfoGuided) return 2;
  if(name === StepperPages.ReviewGuided) return 3;
  return 4
}

export const mapFlowTypeToTabsInterface = (flowType: FlowType ): TabsInterface => {
  switch (flowType) {
    case FlowType.Guided:
      return TabsInterface.guided;
    case FlowType.ROI:
      return TabsInterface.roi;
    case FlowType.Automated:
      return TabsInterface.automated;
    default:
      return flowType as TabsInterface;
  }
};

export function extractValidCribs(data: Charge[]): Charge[] {
  let cribs: Charge[] = [];

  const charges = data.filter(item => {
      // REMOVE CRIBTYPE ALL OBJECTS AND CHECK IF OBJECT IS VALID
      const itemCribs = item.cribs?.filter(obj => obj.cribType !== "All").map(obj => ({ ...item, value: obj.value, cribType: obj.cribType }));
      if (itemCribs?.length) {
          const validCribs = itemCribs.filter((item: Charge) => item.charges_key && item.name && item.value && item.cribType).map((item: Charge) => {
              delete item.cribs;
              return item;
          });
          // THIS UNWINDS CRIB INSIDE ITEM
          cribs = [...cribs, ...validCribs];
      }
      // THIS RETURNS ACTUAL ITEM CONTAINING CRIBS IF ITS VALID WITH CRIBTYPE === ALL
      return item.charges_key && item.name && item.value;
  });
  // CHARGES IS ACTUAL PARENT COST AND CRIBS IS UNWINDED CRIBS ARRAY
  return [...charges, ...cribs]
}

export function getExtraCribs(cribsDefault: string[], exp: boolean, rfid: boolean, accu: boolean): string[] {
  let cribs = cribsDefault || [];
  if (exp) cribs =  [...cribs, MachineSelection.express_toolbox, MachineSelection.express_locker];
  if (rfid) cribs = [...cribs, MachineSelection.accucab, MachineSelection.hybrid_accucab_double, MachineSelection.hybrid_accucab_single, MachineSelection.accudrawer];
  if (accu) cribs = [...cribs, MachineSelection.accuport]

  return cribs;
}