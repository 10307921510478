import { useEffect, useState } from 'react';

import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import ClearIcon from '@mui/icons-material/Clear';
import { Box, Button, Grid, TextField, Tooltip } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { selectStep } from 'features/stepper/stepperSlice';
import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { ItemQuantityGuided, SecurityGuided, timeWindowGuided } from '../guidedModel';
import {
  fetchProductCategory,
  selectedItemQuantityListGuided,
  selectedProductCategoryGuided,
  setItemQuantityListGuided,
  updateItemQuantityListRows
} from '../guidedSlice';
import ItemSelect from './item-select';

interface ListInterface {
  name: string;
  key: keyof ItemQuantityGuided;
  type: string;
  xs: number;
  options: { key: string; name: string }[];
  isDescriptionNeed?: boolean;
  description?: string[];
  category_type?: string[];
}

const ItemList = () => {
  const activeStep = useAppSelector(selectStep);
  const productCategory = useAppSelector(selectedProductCategoryGuided);
  const disabled = activeStep === 3 ? true : false;

  useEffect(() => {
    if (!disabled) {
      dispatch(fetchProductCategory());
    }
  }, []);

  const itemsList: ItemQuantityGuided[] = useAppSelector(selectedItemQuantityListGuided);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const handleRow = (action: string, rowNo?: number) => {
    dispatch(updateItemQuantityListRows({ type: action, rowNo: rowNo || 0 }));
  };

  const [comment, setComment] = useState(itemsList);

  const itemOptions = productCategory && productCategory?.map((ele) => ({ key: ele.item_key, name: ele.item_name }));
  const descriptionData = productCategory && productCategory?.map((ele) => ele.description);
  const securityOptions = Object.values(SecurityGuided);
  const timeWindowOptions = Object.values(timeWindowGuided);
  const list: ListInterface[] = [
    {
      name: 'Product Category',
      key: 'type',
      type: 'select',
      xs: 3,
      options: itemOptions || [],
      isDescriptionNeed: true
    },
    {
      name: 'Security',
      key: 'security',
      type: 'select',
      xs: 1.5,
      options: securityOptions.map((key) => ({ key, name: key })) || [],
      isDescriptionNeed: false
    },
    { name: 'Quantity', key: 'quantity', type: 'number', xs: 1.25, options: [] },
    {
      name: 'Time Window',
      key: 'time_window',
      type: 'select',
      xs: 1.75,
      options: timeWindowOptions.map((key) => ({ key, name: key })) || []
    },
    {
      name: 'Comment',
      key: 'comment',
      type: 'model',
      xs: activeStep === 3 ? 4.5 : 4.1,
      options: []
    }
  ];

  useEffect(() => {
    setComment(itemsList);
  }, [itemsList]);

  const handleQuantityChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    rowNo: number,
    row: ItemQuantityGuided
  ) => {
    const { name, value } = event.target;
    const payload = { ...row, [name]: parseInt(value || '0') };
    dispatch(setItemQuantityListGuided({ item: payload, rowNo }));
  };

  const handleCommentChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, rowNo: number) => {
    const payload = [...comment];
    payload[rowNo] = {
      ...payload[rowNo],
      comment: event.target.value
    };
    setComment(payload);
  };

  const dispatchComment = (value: number) => {
    dispatch(setItemQuantityListGuided({ rowNo: value, item: comment[value] }));
  };

  return (
    <Box sx={{ pt: 2 }}>
      {itemsList.map((row: ItemQuantityGuided, rowNo) => (
        <Grid container spacing={1} key={`${rowNo} item_quantity_guided`} sx={{ mb: 3 }}>
          {list.map((item, i) => {
            const key: keyof ItemQuantityGuided = item.key;
            if (item.type === 'select') {
              return (
                <Grid item xs={item.xs} key={item.key + i}>
                  <ItemSelect
                    label={item.name}
                    options={item.options || []}
                    value={row[key]}
                    kei={item.key}
                    rowNo={rowNo}
                    row={row}
                    category_type={row.category_type}
                    isDescriptionNeed={item.isDescriptionNeed}
                    description={descriptionData || []}
                    required={true}
                    error={!row[key]}
                  />
                </Grid>
              );
            }
            if (item.type === 'number') {
              return (
                <Grid item xs={item.xs} key={item.key + i}>
                  <TextField
                    fullWidth
                    label={item.name}
                    value={row[key]}
                    name={item.key || ''}
                    disabled={disabled}
                    onChange={(e) => handleQuantityChange(e, rowNo, row)}
                    size="medium"
                    required={true}
                    error={!row[key]}
                  />
                </Grid>
              );
            }
            if (item.type === 'model') {
              return (
                <Grid item xs={item.xs} key={item.key + i}>
                  <Tooltip title={`${row[key]}`} arrow>
                    <TextField
                      fullWidth
                      label={item.name}
                      value={comment[rowNo]?.comment || ''}
                      name={item.key || ''}
                      disabled={activeStep === 3}
                      onChange={(e) => handleCommentChange(e, rowNo)}
                      onBlur={() => dispatchComment(rowNo)}
                      sx={{
                        '& .MuiInputBase-input': {
                          overflow: 'hidden',
                          textOverflow: 'ellipsis'
                        }
                      }}
                      size="medium"
                    />
                  </Tooltip>
                </Grid>
              );
            }
          })}

          {activeStep !== 3 && (
            <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
              <ClearIcon sx={{ cursor: 'pointer' }} onClick={() => handleRow('remove', rowNo)} />
            </Grid>
          )}
        </Grid>
      ))}
      {activeStep !== 3 && (
        <Box>
          <Button
            size="medium"
            color="primary"
            sx={{ textTransform: 'capitalize' }}
            startIcon={<AddCircleOutlineOutlinedIcon color="primary" />}
            onClick={() => handleRow('add', 1)}
          >
            {t('Common.addItemLabel') || 'Add Item'}
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default ItemList;
