import { Currency } from 'features/user/userModel';
import {
  ResponseAreaObject,
  Criteria,
  ItemInfoState,
  ItemQuantityGuided,
  CustomerSalesOpportunityInterface,
  CribConfig,
  CustomerDataInterface,
  MachineSelection,
  FlowType
} from '../../features/guided/guidedModel';
import { CostInfoState } from 'features/costInfo/costInfoSlice';
export interface CreateSolutionResponseModal {
  _id: string;
  name: string;
  user_id: string;
  completed: boolean;
  area: ResponseAreaObject;
  criteria_ranking: Criteria[];
  flow_security: string;
  cost_info: CostInfoState;
  flow_type: FlowType;
  item_data: [];
  crib_config: CribConfig;
  machine_selection: MachineSelection[];
  flow_inventory_info: ItemInfoState[];
  flow_inventory_data: ItemQuantityGuided[];
  customer_sales_opportunity: CustomerSalesOpportunityInterface;
  created_by: string;
  createdAt: string;
  updatedAt: string;
  id: string;
  date_time: string;
  delete_status: boolean;
  express_machines: boolean;
  rfid_machines: boolean;
  accuport_machines: boolean;
  shared: boolean;
  customer_data: CustomerDataInterface;
  __v: 0;
  start_date: Date | null;
  currency?: Currency;
  status: string;
}

export interface ExportCheckboxElement {
  name: string;
  value: boolean;
}

export interface ExportCheckboxInterface {
  opportunityView: boolean;
  customerView: boolean;
}


export enum Status {
  active = 'Active',
  onHold = 'On Hold',
  inactive = 'Inactive'
}

export enum StatusValues {
  active = '1',
  onHold = '2',
  inactive = '3'
}

export const StatusTextToValMapping = [
  {
    status: Status.active,
    statusVal: StatusValues.active
  },
  {
    status: Status.inactive,
    statusVal: StatusValues.inactive
  },
  {
    status: Status.onHold,
    statusVal: StatusValues.onHold
  }
];