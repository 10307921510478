import get from 'lodash/get';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { messages } from 'constants/message';
import { AdditionalCostFeesEnum } from 'features/additionalCostFees/additonalCostFeesModel';
import { ApiUpdateSolutionGuided, apiGetRecommendedSolutionGuided } from 'features/guided/guidedAPI';
import { FlowType } from 'features/guided/guidedModel';
import {
  cribConfigDefault,
  selectCostSliderDirty,
  selectGuidedState,
  selectGuidedToSave,
  selectIsDirty,
  selectUserOrgType,
  setCribConfig,
  setIncludeConsignment,
  setInitialCribConfig,
  setSolutionBuiltCurrency,
  setSolutionIsDirty,
  setSolutionRequireUpdate
} from 'features/guided/guidedSlice';
import { selectMachineSelectionRoiState } from 'features/machine-selection-roi/machineSelectionRoiSlice';
import { AlertType } from 'features/stepper/stepperModel';
import { setSnackAlert } from 'features/stepper/stepperSlice';
import { StepperRoiPages, UpdateRoiPayloadInterface } from 'features/stepperRoi/stepperRoiModel';
import { UserOrgType } from 'features/user/userModel';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { formatObjectValues } from 'utils/currency';
import { validateCostFees, validateCostInfo, validateCustomerSales } from './data-validators';
import { extractValidCribs } from 'utils/helpers';

const steps: string[] = Object.values(StepperRoiPages);
const useRoiHook = () => {
  const [enabled, setEnabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const isDirty = useAppSelector(selectIsDirty); // USED TO DECIDE IF NEED TO RUN RECOMMENDARTION
  const requiresSave = useAppSelector(selectGuidedToSave); // USED TO DECIDE IF DATA REQUIRES SAVING

  const dispatch = useAppDispatch();
  const userOrgType = useAppSelector(selectUserOrgType);
  const guided = useAppSelector(selectGuidedState);
  const isCostSliderDirty = useAppSelector(selectCostSliderDirty);

  const isDirectCustomer = userOrgType === UserOrgType.direct;
  const { flowId = ' ', type, stepName } = useParams();
  const stepIndex = stepName && steps.indexOf(stepName) !== -1 ? steps.indexOf(stepName) : 0;

  const navigate = useNavigate();

  const { customer_sales_opportunity, cost_info, additional_costs, service_fees, crib_config } =
    useAppSelector(selectGuidedState);
  const machineSelectionRoi = useAppSelector(selectMachineSelectionRoiState);

  // FUNCTION USED TO MAKE APIS CALLS AND SAVE DATA IN THIS HOOK
  const saveGuidedData = async (payload: UpdateRoiPayloadInterface) => {
    setLoading(true);
    try {
      const step = stepName === steps[steps.length - 1] && isCostSliderDirty ? 'cost_sliders_guided' : stepName;
      const response = await ApiUpdateSolutionGuided(payload, step);
      if (response.data?.errors?.length) {
        setLoading(false);
        stepName !== StepperRoiPages.machineSelection && setEnabled(false);
        const error: string = response.data.errors?.join(',');
        dispatch(setSnackAlert({ open: true, type: AlertType.error, message: error, timeout: 6000 }));
        return { data: null, error };
      }
      const includeConsignment = get(response, 'data.include_consignment');
      // this impacts working capital calculations on result screen
      if (includeConsignment !== guided.include_consignment) dispatch(setIncludeConsignment(includeConsignment));
      dispatch(setSolutionRequireUpdate(false));
      setLoading(false);
      return { data: response.data, error: null };
    } catch (err) {
      setLoading(false);
      // PAGE VERIFICATION CANNOT BE EXECUTED UNLESS NEXT BUTTON IS CLICKED THUS SET ENABLED TO FALSE ON API FAIL IS REMOVED FOR MACHINE SELECTION PAGE
      stepName !== StepperRoiPages.machineSelection && setEnabled(false);
      dispatch(setSnackAlert({ open: true, type: AlertType.error, message: messages.error_message, timeout: 20000 }));
      return { data: null, error: err };
    }
  };

  // HANDLE FLOW API ERRORS
  useEffect(() => {
    if (crib_config.Error) {
      setEnabled(false);
    }
  }, [crib_config.Error]);

  // VALIDATE MACHINE SELECTION PAGE
  useEffect(() => {
    if (stepName === StepperRoiPages.machineSelection) {
      setEnabled(machineSelectionRoi.isNextBtnEnabled);
    }
  }, [stepName, machineSelectionRoi.isNextBtnEnabled]);

  // VALIDATE COST INFO PAGE
  useEffect(() => {
    if (stepName === StepperRoiPages.costInfoGuided) {
      const costValidity = validateCostInfo(cost_info);
      const customerSalesValidity = validateCustomerSales(customer_sales_opportunity);
      const additionalCostValidity = validateCostFees(additional_costs);
      const additionalFeesValidity = validateCostFees(guided.service_fees);
      if (costValidity && customerSalesValidity && additionalCostValidity && additionalFeesValidity) {
        setEnabled(true);
      } else {
        setEnabled(false);
      }
    }
  }, [customer_sales_opportunity, cost_info, additional_costs, service_fees, isDirectCustomer, stepName]);

  const getRecommendation = async () => {
    try {
      setLoading(true);
      const result = await apiGetRecommendedSolutionGuided(flowId);
      result.data?.crib_config && dispatch(setCribConfig({ ...result.data.crib_config, flowId }));
      result.data?.crib_config && dispatch(setInitialCribConfig({ ...result.data.crib_config, flowId }));
      result.data && setSolutionBuiltCurrency(result.data?.currency);
      // SET LOADING HERE
      setLoading(false);
      setEnabled(true);
      dispatch(setSolutionIsDirty(false));
    } catch (err) {
      dispatch(setSnackAlert({ open: true, type: AlertType.error, message: messages.error_message, timeout: 6000 }));
      // CLOSE LOADING HETRE
      setLoading(false);
      setEnabled(false);
    }
  };
  // VALIDATE REVIEW SELECTION PAGE
  useEffect(() => {
    if (stepName === StepperRoiPages.review) {
      guided.approved ? setEnabled(true) : setEnabled(false);
      if (flowId && isDirty) getRecommendation();
    }
  }, [stepName, guided.approved, flowId, isDirty]);

  // VALIDATE ROI RESULT PAGE
  useEffect(() => {
    if (stepName === StepperRoiPages.resultRoi) {
      setEnabled(true);
    }
  }, [stepName]);

  // GENERATES PAYLOAD FOR API CALL
  const payaloadGenerator = () => {
    const validCosts = extractValidCribs(guided[AdditionalCostFeesEnum.additionalCosts]);
    const validFees = extractValidCribs(guided[AdditionalCostFeesEnum.additionalFees]);
    return {
      completed: false,
      flowId: flowId,
      shared: guided.shared,
      flow_type: FlowType.ROI,
      cost_info: formatObjectValues(guided.cost_info),
      customer_sales_opportunity: formatObjectValues(guided.customer_sales_opportunity),
      customer_view_results: guided.customer_view_results,
      customer_data: formatObjectValues(guided.customer_data),
      machine_selection_roi: guided.machine_selection_roi,
      cm_hosting_data: guided.cm_hosting_data,
      [AdditionalCostFeesEnum.additionalCosts]: validCosts,
      [AdditionalCostFeesEnum.additionalFees]: validFees,
      connections: guided.connections,
      include_consignment: guided.include_consignment
    };
  };

  // HANDLE NEXT
  const handleNext = async () => {
    const nextIndex = stepIndex === steps.length - 1 ? steps.length - 1 : stepIndex + 1;
    if (requiresSave && stepName !== StepperRoiPages.review) {
      const payload = payaloadGenerator();
      dispatch(setCribConfig(cribConfigDefault));
      const { data } = await saveGuidedData({ ...payload, shared: false });
      if (data) {
        navigate(`/flow/${flowId}/${type}/${steps[nextIndex]}`);
      }
    } else {
      navigate(`/flow/${flowId}/${type}/${steps[nextIndex]}`);
    }
  };

  // HANDLE BACK
  const handleBack = () => {
    if (stepName) {
      const prevIndex = stepIndex === 0 ? 0 : stepIndex - 1;
      navigate(`/flow/${flowId}/${type}/${steps[prevIndex]}`);
    }
  };

  // HANDLE FINISH I.E. SAME AS SAVING BUT ADDS COMPLETED = TRUE AND REDIRECTS
  const handleFinish = async () => {
    if (stepName) {
      const payload = payaloadGenerator();
      const { data } = await saveGuidedData({ ...payload, completed: true });
      if (data) navigate('/');
    }
  };

  // HANDLE DONE I.E. SAVES DATA AND REDIRECTS
  const handleDone = async () => {
    if (stepName) {
      const payload = payaloadGenerator();
      const { data } = await saveGuidedData({ ...payload, shared: false });
      if (data) navigate('/');
    }
  };

  // RESTORE SOLUTION I.E. WORKS IF PAGE IS NOT REFRESHED AFTER MAKING CHANGES
  const handleRestore = async () => {
    if (guided.initial) {
      const payload = {
        flowId: flowId,
        flow_type: FlowType.ROI,
        completed: guided.initial.completed,
        shared: guided.initial.shared,
        cost_info: formatObjectValues(guided.initial?.cost_info),
        customer_sales_opportunity: formatObjectValues(guided.initial?.customer_sales_opportunity),
        machine_selection_roi: guided.initial?.machine_selection_roi,
        cm_hosting_data: guided.initial?.cm_hosting_data,
        [AdditionalCostFeesEnum.additionalCosts]: guided.initial
          ? guided.initial[AdditionalCostFeesEnum.additionalCosts].filter(
              (item) => item.charges_key && item.name && item.value
            )
          : [],
        [AdditionalCostFeesEnum.additionalFees]: guided.initial
          ? guided.initial[AdditionalCostFeesEnum.additionalFees].filter(
              (item) => item.charges_key && item.name && item.value
            )
          : [],
        connections: guided.initial.connections,
        include_consignment: guided.include_consignment
      };
      const { data } = await saveGuidedData(payload);
      if (data) navigate('/');
    }
  };

  // RETURN FUNCTIONS AND STATES
  return { enabled, handleNext, handleBack, nextLoading: loading, handleFinish, handleDone, handleRestore };
};

export default useRoiHook;
