import { TabsInterface } from 'features/recomendation-tab/RecomendationTab';
import { StepperGuidedPages } from 'features/stepper/stepperModel';
import { StepperAutomatedPages } from 'features/stepperAutomated/stepperAutomatedModel';
import { StepperRoiPages } from 'features/stepperRoi/stepperRoiModel';
import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

// CONVERT PAGE ENUM TO ARRAY BASED ON POSITION AND IS ORDERED
const roiSteps: string[] = Object.values(StepperRoiPages);
const guidedSteps: string[] = Object.values(StepperGuidedPages);
const automatedSteps: string[] = Object.values(StepperAutomatedPages);

const useRoute = () => {
    // EXTRACT PARAMS FROM URL
    const { type: flowType, flowId } = useParams();
    const navigate = useNavigate();

    // FUNCTION TO HANDLE ROUTINGH
    const routeTo = useCallback(( stepNo: number ) => {
        // HANDLE ROUTE BASED ON NUMBER
        const steps = flowType === TabsInterface.guided ? guidedSteps : flowType === TabsInterface.roi ? roiSteps: automatedSteps;
        
        // GET STEO NAME USING INDEXING OVER ARRAY 
        const step = steps[stepNo];

        // NAVIGATE TO URL
        navigate(`/flow/${flowId}/${flowType}/${step}`)
    },[flowType, flowId])

    return { routeTo }
}

export default useRoute